<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="flex flex-col">
      <div class="flex items-center justify-end space-x-2">
        <base-search v-model="search" @input="debounceSearch" />
      </div>
      <div class="flex my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Loading v-if="loading" />
          <Datatable
            v-else
            :total="getProducts.meta.page.total"
            :perPage="getProducts.meta.page.perPage"
            :currentPage="getProducts.meta.page.currentPage"
            @pagechanged="onPageChangeProducts"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    @click="sortData('code')"
                    scope="col"
                    class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kode Barang
                  </th>
                  <th
                    @click="sortData('name')"
                    scope="col"
                    class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Nama Barang
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Kelompok Produk
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody v-if="getProducts.data?.length > 0">
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getProducts.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click="detailBarang(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                  >
                    {{ data.attributes.code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ data.attributes.name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      data.attributes['product_category_name']
                        ? data.attributes['product_category_name']
                        : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
    <loading v-if="isLoading" />
    <!-- Modal Detail -->
    <product-detail-modal
      :visible="openDetail"
      :withAdditionalProductPrices="true"
      :withActiveToggle="false"
      view-as="kantor-pelayanan"
      @close="openDetail = false"
    >
      <template #attribute="{ product }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.code }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.name }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Isi Satuan</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.unit_name }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">P/N</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ !!product.data.attributes.is_point ? 'Point' : 'Non Point' }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">PV</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.point }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">BV</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.bonus | toCurrency }}
          </dd>
        </div>
      </template>
    </product-detail-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseSearch from '@/components/base/Search.vue';
import ProductDetailModal from '@/components/product/ProductDetailModal.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    BaseSearch,
    ProductDetailModal,
  },
  data() {
    return {
      search: null,
      openDetail: false,
      success: false,
      failed: false,
      loading: false,
      dataProduct: {},
      sort: {
        name: 'createdAt',
        direction: 'desc',
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
      getProduct: 'products/getProduct',
    }),

    filteredData() {
      return this.getResults.filter((products) =>
        products.attributes.name
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      fetchProductById: 'products/fetchProductById',
      exportProducts: 'products/exportProducts',
      deleteProduct: 'products/deleteProduct',
    }),
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    closeModal() {
      this.success = this.failed = false;
      this.openDetail = !this.openDetail;
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
          'product-categories': 'name',
        },
      });
    },
    failedModal() {
      this.failed = !this.failed;
    },
    getProductCategory(id) {
      let category = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return category[0].attributes.name;
    },
    getProductPrice(areaName, buyerType) {
      let productPrice = this.getProducts.included.filter(function (el) {
        return (
          el.attributes.area === areaName &&
          el.attributes['buyer-type'] === buyerType
        );
      });
      return productPrice[0];
    },
    onPageChangeProducts(page) {
      this.fetchProducts({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    },
    sortData(sortName) {
      if (this.sort.name === sortName) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.name = sortName;
        this.sort.direction = 'asc';
      }
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    },
    debounceSearch: debounce(function () {
      this.fetchProducts({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
        sortName: this.sort.name,
        sortDirection: this.sort.direction,
        fields: {
          products: 'product_category_name,code,supplier_code,name',
        },
      });
    }, 300),
    detailBarang(product) {
      this.fetchProductById({
        id: product.id,
        include: 'product-prices',
      }).then(() => {
        this.openDetail = !this.openDetail;
        this.dataProduct = this.getProduct.data;
      });
      // this.dataProduct = product;
      // this.openDetail = !this.openDetail;
    },
    downloadProduct() {
      this.exportProducts({
        pageSize: this.getProducts.meta.page.total,
      });
    },
    deleteData(data) {
      this.deleteProduct({
        id: data.id,
      }).then((response) => {
        if (!response) {
          this.failed = false;
        }
      });
    },
  },
  created() {
    this.loading = true;
    this.fetchProducts({
      pageNumber: 1,
      pageSize: 5,
      sortName: this.sort.name,
      sortDirection: this.sort.direction,
      fields: {
        products: 'product_category_name,code,supplier_code,name',
      },
    }).then(() => (this.loading = false));
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
