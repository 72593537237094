var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Datatable',{attrs:{"total":_vm.getOffices.meta.page.total,"perPage":_vm.getOffices.meta.page.perPage,"currentPage":_vm.getOffices.meta.page.currentPage,"meta":_vm.getOffices.meta,"is-empty":!_vm.getOffices.data?.length,"columns":[
    {
      name: 'Kode Stockist',
    },
    {
      name: 'Wilayah Harga',
    },
    {
      name: 'No. Telp',
    },
    {
      name: 'Alamat Stockist',
    },
  ],"cursor":""},on:{"pagechanged":_vm.onPageChangeStockist},scopedSlots:_vm._u([{key:"tbody",fn:function(){return [_c('tbody',[_vm._l((_vm.getOffices.data),function(data,dataIdx){return [(
            _vm.getSingleIncluded(_vm.getOffices, data.relationships.office.data.id)
          )?_c('tr',{key:data.id,staticClass:"cursor-pointer bg-white hover:bg-green-100",class:dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50',on:{"click":function($event){$event.stopPropagation();return _vm.onClickRow(data)}}},[_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-700"},[_c('div',[_c('p',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(data.attributes.code)+" ")]),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(data.attributes.name)+" ")])])]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-500"},[(
                _vm.getSingleIncluded(
                  _vm.getOffices,
                  data.relationships.office.data.id
                ).attributes.pulau
              )?_c('span',[_vm._v("Wilayah "+_vm._s(['Jawa', 'I', 'II', 'III', 'IV', 'V', 'loading...'].find( (_, i) => parseInt( _vm.getSingleIncluded( _vm.getOffices, data.relationships.office.data.id ).attributes.pulau ) == i )))]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm.getSingleIncluded(_vm.getOffices, data.relationships.office.data.id) .attributes.phone)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm text-gray-500"},[_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.getSingleIncluded( _vm.getOffices, data.relationships.office.data.id ).attributes.address)+" ")]),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getSingleIncluded( _vm.getOffices, data.relationships.office.data.id ).attributes.email)+" ")])])])]):_vm._e()]})],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }