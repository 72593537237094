<template>
  <div>
    <div class="flex items-center justify-end space-x-2">
      <base-search
        placeholder="Cari Kode"
        v-model="search"
        v-on:input="debounceSearch"
      />
    </div>
    <Datatable
      :total="getSimpleOffices.meta.page.total"
      :perPage="getSimpleOffices.meta.page.perPage"
      :currentPage="getSimpleOffices.meta.page.currentPage"
      @pagechanged="onPageChange"
      :meta="getSimpleOffices.meta"
      cursor
    >
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Kode Stockist Center
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Nama Stockist Center
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              No. Telp
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Alamat Stockist Center
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody>
          <tr
            class="cursor-pointer bg-white hover:bg-green-100"
            v-for="(data, dataIdx) in getSimpleOffices.data"
            :key="data.id"
            :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            @click="onClickRow(data)"
          >
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
            >
              {{ data.attributes.code }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ data.attributes.name }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ data.attributes.phone ?? '-' }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <div>
                <p>
                  {{ data.attributes.address }}
                </p>
                <p class="text-xs">
                  {{ data.attributes.email }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </Datatable>
    <div
      v-if="openAdd"
      class="fixed inset-0 z-10 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
        >
          <form @submit.prevent="addStockist">
            <div>
              <div
                class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
              >
                <div>
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    Tambah Stockist Center
                  </h3>
                </div>
              </div>
              <div class="py-2 px-6">
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default relative w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Kode Stockist Center
                    </label>
                    <button
                      type="button"
                      @click="openModal"
                      class="absolute inset-y-0 top-4 right-4 flex cursor-pointer items-center pl-3"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 text-gray-500 hover:text-gray-700"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                    <input
                      v-model="dataStockist.kode_stockist"
                      ref="focusNamaBarang"
                      @blur="searchStockist"
                      type="text"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Nama Stockist"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Nama Stockist Center
                    </label>
                    <input
                      v-model="dataStockist.nama_stockist"
                      typeref="focusNamaStockist"
                      type="text"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Nama Stockist"
                    />
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      No.Hp
                    </label>
                    <input
                      v-model="dataStockist.phone"
                      type="text"
                      name="no_hp"
                      id="no_hp"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan nomor handphone pemilik stockist"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      v-model="dataStockist.email"
                      typeref="focusNamaStockist"
                      type="text"
                      name="name"
                      id="name"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan email Stockist"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
              >
                <div>
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    Informasi Alamat
                  </h3>
                </div>
              </div>
              <div class="py-2 px-6">
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Provinsi
                    </label>
                    <select
                      v-model="dataStockist.provinsi"
                      @change="changeProvince"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option
                        :value="{
                          id: '',
                          name: '',
                        }"
                      >
                        Pilih Provinsi
                      </option>
                      <option
                        v-for="provinsi in getProvinces.data"
                        :key="provinsi.id"
                        :value="{
                          id: provinsi.id,
                          name: provinsi.attributes.name,
                        }"
                      >
                        {{ provinsi.attributes.name }}
                      </option>
                    </select>
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Kabupaten
                    </label>
                    <select
                      v-model="dataStockist.kota"
                      @change="changeCity"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option
                        :value="{
                          id: '',
                          name: '',
                        }"
                      >
                        Pilih Kabupaten
                      </option>
                      <option
                        v-for="kota in getCities.data"
                        :key="kota.id"
                        :value="{
                          id: kota.id,
                          name: kota.attributes.name,
                        }"
                      >
                        {{ kota.attributes.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Kecamatan
                    </label>
                    <select
                      v-model="dataStockist.kecamatan"
                      @change="changeDistrict"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option
                        :value="{
                          id: '',
                          name: '',
                        }"
                      >
                        Pilih Kecamatan
                      </option>
                      <option
                        v-for="kecamatan in getDistricts.data"
                        :key="kecamatan.id"
                        :value="{
                          id: kecamatan.id,
                          name: kecamatan.attributes.name,
                        }"
                      >
                        {{ kecamatan.attributes.name }}
                      </option>
                    </select>
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Desa
                    </label>
                    <select
                      v-model="dataStockist.desa"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Stok Barang"
                    >
                      <option
                        :value="{
                          id: '',
                          name: '',
                        }"
                      >
                        Pilih Desa
                      </option>
                      <option
                        v-for="desa in getVillages.data"
                        :key="desa.id"
                        :value="{
                          id: desa.id,
                          name: desa.attributes.name,
                        }"
                      >
                        {{ desa.attributes.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="alamat"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Alamat Suplier
                    </label>
                    <input
                      v-model="dataStockist.alamat"
                      type="text"
                      name="alamat"
                      id="alamat"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Alamat Suplier"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                class="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
              >
                <div>
                  <h3 class="text-lg font-semibold leading-6 text-gray-900">
                    Deposit
                  </h3>
                </div>
              </div>
              <div class="py-2 px-6">
                <div class="mt-4 flex sm:flex-row sm:gap-x-4">
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Deposit Belance
                    </label>
                    <input
                      v-model="dataStockist.deposit_balance"
                      type="number"
                      name="deposit_balance"
                      id="deposit_balance"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan nomor handphone pemilik stockist"
                    />
                  </div>
                  <div class="field-inset-default w-full">
                    <label
                      for="name"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Deposit Multiplier
                    </label>
                    <input
                      v-model="dataStockist.deposit_multiplier"
                      type="number"
                      name="deposit_multiplier"
                      id="deposit_multiplier"
                      class="block w-full border-0 px-0 pt-2 pb-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan nomor handphone pemilik stockist"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-5">
              <div class="flex justify-end">
                <button
                  type="submit"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
                >
                  Simpan
                </button>
                <button
                  @click="clearDataStockist"
                  type="button"
                  class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  Batal
                </button>
                <button
                  @click="openAdd = !openAdd"
                  class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
                >
                  Selesai
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="openListStockist"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
          >
            <div class="flex justify-between px-4 py-5 sm:px-6">
              <div>
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Daftar Stockist
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  Berdasarkan data barang yang telah dibuat
                </p>
              </div>
              <div>
                <img src="@/assets/images/logo.png" alt="" class="w-52" />
              </div>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
              <div class="flex justify-end">
                <base-search
                  v-model="searchStockistDetail"
                  @input="debounceSearchDetail"
                  placeholder="Cari kode atau nama"
                />
              </div>
              <div class="my-2 flex sm:-mx-6 lg:-mx-8">
                <div
                  class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
                >
                  <Datatable
                    :total="dataDetailStockist.meta.page.total"
                    :perPage="dataDetailStockist.meta.page.perPage"
                    :currentPage="dataDetailStockist.meta.page.currentPage"
                    @pagechanged="onPageChangeDetailStockist"
                  >
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Kode Stockist
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Nama Stockist
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            No. Telp
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Alamat Stockist
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="cursor-pointer bg-white hover:bg-green-100"
                          v-for="(data, dataIdx) in dataDetailStockist.data"
                          :key="data.id"
                          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                          @click="changeStockist(data)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                          >
                            {{ data.attributes.code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ data.attributes.name }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ data.attributes.phone }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ data.attributes.email }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{ data.attributes.address }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </div>
              </div>
            </div>
            <div class="flex justify-end">
              <base-button
                @click="openListStockist = !openListStockist"
                type="button"
                color="white"
              >
                Tutup
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading"> Data Berhasil disimpan </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <base-button @click.prevent="addMoreStockist">
                  Tambah Lagi
                </base-button>
                <base-button color="white" @click="closeModal">
                  Selesai
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading">
                  {{ getError }}
                </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <button
                  @click.prevent="failedModal"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Ulangi
                </button>
                <button
                  @click="closeModal"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                >
                  Selesai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <loading v-if="isLoading" />
    <office-detail-modal
      view-as="stockist-center"
      read-only
      v-model="openDetail"
      @update="() => (openDetail = false)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import OfficeDetailModal from '@/components/office/office-detail-modal.vue';
import { getRelationships } from '@/services/utils.service';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
    BaseButton,
    OfficeDetailModal,
  },
  data() {
    return {
      search: null,
      isLoading: false,
      searchStockistDetail: '',
      anyStockist: false,
      openListStockist: false,
      openAdd: false,
      openDetail: false,
      success: false,
      failed: false,
      stockist_office_category: null,
      dataDetailStockist: {
        data: [],
        meta: {
          page: {},
        },
      },
      dataStockist: {
        id: '',
        kode_stockist: '',
        nama_stockist: '',
        no_faktur: '',
        phone: '',
        email: '',
        alamat: '',
        users: {
          data: [],
          meta: {
            page: {
              total: '',
              perPage: '',
              currentPage: '',
            },
          },
        },
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        deposit_balance: '',
        deposit_multiplier: '',
        area: null,
      },
    };
  },
  created() {
    this.loadOffices();

    this.stockist_office_category = this.getAvailableOfficeCategories.filter(
      function (el) {
        return el.attributes.name === 'Stockist Center';
      }
    );
    const handleEscape = () => {
      this.openDetail =
        this.openListStockist =
        this.success =
        this.failed =
        this.openAdd =
          false;
      this.search = '';
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getProvinces: 'indonesia/getProvinces',
      getCities: 'indonesia/getCities',
      getDistricts: 'indonesia/getDistricts',
      getVillages: 'indonesia/getVillages',
      getOffice: 'offices/getOffice',
      getError: 'offices/getError',
      getAvailableOfficeCategories:
        'office_categories/getAvailableOfficeCategories',
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficesLoading: 'simple_offices/getLoading',
    }),
  },
  methods: {
    getRelationships,
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchProvinces: 'indonesia/fetchProvinces',
      fetchCities: 'indonesia/fetchCities',
      updateOffice: 'offices/updateOffice',
      fetchUsersByOffice: 'users/fetchUsersByOffice',
      fetchDistricts: 'indonesia/fetchDistricts',
      fetchVillages: 'indonesia/fetchVillages',
      fetchOffice: 'offices/fetchOffice',
      createStockistCenter: 'stockists_center/createStockistCenter',
      updateStockistCenter: 'stockists_center/updateStockistCenter',
      updateAddress: 'addresses/updateAddress',
      updateUser: 'users/updateUser',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
    }),
    loadOffices(params) {
      this.fetchSimpleOffices({
        'filter[search]': this.search ? this.search : undefined,
        'filter[office_category_id]': 3,
        'fields[simple-offices]': 'code,name,temp_code,office,office_id',
        include: 'office',
        'page[limit]': 5,
        ...params,
      });
    },
    onActiveUserChange(user) {
      const payload = {
        data: {
          type: 'users',
          id: user.id,
          attributes: {
            is_suspended: !user.attributes.is_suspended,
          },
        },
      };
      this.updateUser(payload);
    },
    openAddStockist() {
      this.openAdd = !this.openAdd;
      this.fetchProvinces({
        pageNumber: 1,
      });
      this.clearDataStockist();
    },
    openModal() {
      this.openListStockist = !this.openListStockist;
      this.fetchSimpleOffices({
        pageNumber: 1,
        pageSize: 5,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    },
    closeModal() {
      this.success = this.failed = false;
      this.clearDataStockist();
      this.openAdd = !this.openAdd;
      this.loadOffices();
    },
    addMoreStockist() {
      this.success = !this.success;
      this.clearDataStockist();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearDataStockist();
    },
    debounceSearch: debounce(function () {
      this.loadOffices({
        search: this.search,
      });
    }, 300),
    debounceSearchDetail: debounce(function () {
      this.fetchSimpleOffices({
        pageNumber: 1,
        pageSize: 5,
        search: this.searchStockistDetail,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    }, 300),
    async onPageChange(page) {
      await this.loadOffices(page);
    },
    onPageChangeDetailStockist(page) {
      this.fetchSimpleOffices({
        pageNumber: page,
        pageSize: 5,
        search: this.searchStockistDetail,
      }).then((response) => {
        this.dataDetailStockist = response.data;
      });
    },
    async onClickRow(office) {
      office = getRelationships(
        this.getSimpleOffices,
        office.relationships.office.data.id
      );
      this.fetchOfficeById({
        office_id: office.id,
        params: {
          include: 'addresses,area',
        },
      });
      this.fetchUsersByOffice({
        officeId: office.id,
      });
      this.isLoading = false;
      this.openDetail = true;
    },
    changeStockist(data) {
      this.dataStockist.kode_stockist = data.attributes.code;
      this.searchStockist();
      this.searchStockistDetail = '';
      this.openListStockist = !this.openListStockist;
    },
    changeProvince() {
      this.fetchCities({
        province_id: this.dataStockist.provinsi.id,
        pageNumber: 1,
      });
      this.dataStockist.kota =
        this.dataStockist.kecamatan =
        this.dataStockist.desa =
          {
            id: '',
            name: '',
          };
    },
    changeCity() {
      this.fetchDistricts({
        city_id: this.dataStockist.kota.id,
        pageNumber: 1,
      });
      this.dataStockist.kecamatan = this.dataStockist.desa = {
        id: '',
        name: '',
      };
    },
    changeDistrict() {
      this.fetchVillages({
        district_id: this.dataStockist.kecamatan.id,
        pageNumber: 1,
      });
      this.dataStockist.desa = {
        id: '',
        name: '',
      };
    },
    getAddresses(id) {
      let address = this.getSimpleOffices.included.filter(function (el) {
        return el.id === id;
      });
      return address[0];
    },
    getArea(areaId) {
      return getRelationships(this.getSimpleOffices, areaId);
    },
    searchStockist() {
      if (this.dataStockist.kode_stockist) {
        this.fetchSimpleOffices({
          keyword: this.dataStockist.kode_stockist,
        }).then(() => {
          if (this.getSimpleOffices.data.length > 0) {
            this.anyStockist = true;
            this.dataStockist.id = this.getSimpleOffices.data[0].id;
            this.dataStockist.kode_stockist =
              this.getSimpleOffices.data[0].attributes.code;
            this.dataStockist.nama_stockist =
              this.getSimpleOffices.data[0].attributes.name;
            this.dataStockist.phone =
              this.getSimpleOffices.data[0].attributes.phone;
            this.dataStockist.email =
              this.getSimpleOffices.data[0].attributes.email;
            this.dataStockist.alamat =
              this.getSimpleOffices.data[0].attributes.address;
            const addresses = this.getAddresses(
              this.getSimpleOffices.data[0].relationships.addresses.data[0].id
            );
            this.dataStockist.provinsi.id = addresses.attributes.province_id;
            this.dataStockist.provinsi.name =
              addresses.attributes.province_name;
            this.changeProvince();
            this.dataStockist.kota.id = addresses.attributes.city_id;
            this.dataStockist.kota.name = addresses.attributes.city_name;
            this.changeCity();
            this.dataStockist.kecamatan.id = addresses.attributes.district_id;
            this.dataStockist.kecamatan.name =
              addresses.attributes.district_name;
            this.changeDistrict();
            this.dataStockist.desa.id = addresses.attributes.village_id;
            this.dataStockist.desa.name = addresses.attributes.village_name;
          } else {
            this.anyStockist = false;
            this.dataStockist = {
              id: '',
              kode_stockist: this.dataStockist.kode_stockist,
              nama_stockist: '',
              phone: '',
              email: '',
              alamat: '',
              provinsi: {
                id: '',
                name: '',
              },
              kota: {
                id: '',
                name: '',
              },
              kecamatan: {
                id: '',
                name: '',
              },
              desa: {
                id: '',
                name: '',
              },
              area: null,
            };
          }
        });
      }
    },
    addStockist: function () {
      const payload = {
        data: {
          type: 'offices',
          attributes: {
            office_type: 'stockist_center',
            code: this.dataStockist.kode_stockist,
            name: this.dataStockist.nama_stockist,
            pasif: null,
            urut: null,
            address: this.dataStockist.alamat,
            address2: null,
            area: null,
            kta: null,
            phone: this.dataStockist.phone,
            email: this.dataStockist.email,
            pulau: null,
            address_details: [
              {
                province_id: parseInt(this.dataStockist.provinsi.id),
                city_id: parseInt(this.dataStockist.kota.id),
                district_id: parseInt(this.dataStockist.kecamatan.id),
                village_id: parseInt(this.dataStockist.desa.id),
              },
            ],
          },
          relationships: {
            'office-category': {
              data: {
                type: 'office-categories',
                id: this.stockist_office_category[0].id,
              },
            },
          },
        },
      };
      if (!this.anyStockist) {
        this.createStockistCenter(payload).then((response) => {
          if (response) {
            this.success = true;
            this.fetchSimpleOffices({
              pageNumber: 1,
              pageSize: 5,
            }).then((response) => {
              this.getSimpleOffices = response.data;
            });
          } else {
            this.failed = true;
          }
        });
      } else {
        payload.data.id = this.dataStockist.id;
        this.updateStockistCenter(payload).then((response) => {
          if (response) {
            this.fetchSimpleOffices({
              pageNumber: 1,
              pageSize: 5,
            }).then((response) => {
              this.getSimpleOffices = response.data;
            });
            const payloadAddress = {
              data: {
                type: 'addresses',
                id: response.data.data.relationships['addresses'].data[0].id,
                attributes: {
                  detail: 'test',
                },
                relationships: {
                  office: {
                    data: {
                      type: 'offices',
                      id: this.dataStockist.id,
                    },
                  },
                  province: {
                    data: {
                      type: 'provinces',
                      id: this.dataStockist.provinsi.id,
                    },
                  },
                  city: {
                    data: {
                      type: 'cities',
                      id: this.dataStockist.kota.id,
                    },
                  },
                  district: {
                    data: {
                      type: 'districts',
                      id: this.dataStockist.kecamatan.id,
                    },
                  },
                  village: {
                    data: {
                      type: 'villages',
                      id: this.dataStockist.desa.id,
                    },
                  },
                },
              },
            };
            this.updateAddress(payloadAddress).then((response) => {
              if (response) {
                this.success = true;
              }
            });
          } else {
            this.failed = true;
          }
        });
      }
    },
    onActiveChange() {
      const payload = {
        data: {
          type: 'offices',
          id: this.dataStockist.id,
          attributes: {
            is_suspended: !this.dataStockist.is_suspended,
          },
        },
      };
      this.updateOffice(payload).then(() => {
        this.fetchSimpleOffices({
          pageNumber: 1,
          pageSize: 5,
        });
      });
    },
    clearDataStockist() {
      this.dataStockist = {
        kode_stockist: '',
        nama_stockist: '',
        phone: '',
        email: '',
        alamat: '',
        provinsi: {
          id: '',
          name: '',
        },
        kota: {
          id: '',
          name: '',
        },
        kecamatan: {
          id: '',
          name: '',
        },
        desa: {
          id: '',
          name: '',
        },
        deposit_balance: '',
        deposit_multiplier: '',
        area: null,
      };
    },
    saveNoFaktur() {
      const payload = {
        data: {
          id: StorageService.getUser().office_id,
          type: 'offices',
          attributes: {
            prefix_order: this.dataStockist.prefix_order,
          },
        },
      };
      this.updateOffice(payload).then((res) => {
        this.$store.dispatch(
          'alert/createAlert',
          {
            data: res
              ? 'Berhasil mengubah nomor depan faktur'
              : 'No depan faktur gagal diubah ',
          },
          { root: true }
        );
      });
    },
  },
};
</script>
