<template>
  <Datatable
    :total="getOffices.meta.page.total"
    :perPage="getOffices.meta.page.perPage"
    :currentPage="getOffices.meta.page.currentPage"
    @pagechanged="onPageChangeStockist"
    :meta="getOffices.meta"
    :is-empty="!getOffices.data?.length"
    :columns="[
      {
        name: 'Kode Stockist',
      },
      {
        name: 'Wilayah Harga',
      },
      {
        name: 'No. Telp',
      },
      {
        name: 'Alamat Stockist',
      },
    ]"
    cursor
  >
    <template v-slot:tbody>
      <tbody>
        <template v-for="(data, dataIdx) in getOffices.data">
          <tr
            v-if="
              getSingleIncluded(getOffices, data.relationships.office.data.id)
            "
            class="cursor-pointer bg-white hover:bg-green-100"
            :key="data.id"
            :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            @click.stop="onClickRow(data)"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-700">
              <div>
                <p class="font-bold">
                  {{ data.attributes.code }}
                </p>
                <p class="text-xs">
                  {{ data.attributes.name }}
                </p>
              </div>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <span
                v-if="
                  getSingleIncluded(
                    getOffices,
                    data.relationships.office.data.id
                  ).attributes.pulau
                "
                >Wilayah
                {{
                  ['Jawa', 'I', 'II', 'III', 'IV', 'V', 'loading...'].find(
                    (_, i) =>
                      parseInt(
                        getSingleIncluded(
                          getOffices,
                          data.relationships.office.data.id
                        ).attributes.pulau
                      ) == i
                  )
                }}</span
              >
              <span v-else>-</span>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{
                getSingleIncluded(getOffices, data.relationships.office.data.id)
                  .attributes.phone
              }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <div>
                <p>
                  {{
                    getSingleIncluded(
                      getOffices,
                      data.relationships.office.data.id
                    ).attributes.address
                  }}
                </p>
                <p class="text-xs">
                  {{
                    getSingleIncluded(
                      getOffices,
                      data.relationships.office.data.id
                    ).attributes.email
                  }}
                </p>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';

export default {
  name: 'ComponentName',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOffices: 'offices/getOffices',
    }),
  },
  components: {
    Datatable,
  },
  methods: {
    onClickRow(data) {
      this.$emit('click-row', data);
    },
    onPageChangeStockist(page) {
      this.$emit('change-page', page);
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
